let resumeData = {
    "imagebaseurl":"https://jollyperson.github.io/",
    "name": "Harkaran Bual",
    "role": "Frontend Developer and Data Scientist",
    "linkedinId":" https://github.com/jollyperson",
    "skypeid": "Your skypeid",
    "roleDescription": "I enjoy Maths and Computer sciences. Particulary backend services from network design to hardware hacking. I'm also an avid DJ.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/jollyperson",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
            "url":"http://github.com/jollyperson",
          "className":"fa fa-twitter"
        }
      ],
    "aboutme":"I am currently a pre-final year student, studying Mathematics, Further Mathematics, Computer Science and Physics beyond an A level standard. I am diving into machine learning and computational data sciences.",
    "address":"England",
    "website":"https://jollyperson.github.io",
    "education":[
      {
        "UniversityName":"High School",
        "specialization":"Further Mathematics, Computer Science, Physics",
        "MonthOfPassing":"Aug",
        "YearOfPassing":"2020",
        "Achievements":"High Passing"
      },

    ],
    "work":[
      {
        "CompanyName":"Pets & Friends",
        "specialization":"E-Commerce Work",
        "MonthOfLeaving":"Aug",
        "YearOfLeaving":"2022",
        "Achievements":"SEO Tagging, Data Management, Web-Chat service management"
      },

    ],
    "skillsDescription":"My Skills",
    "skills":[
      {
        "skillname":"Computational Data Science"
      },
      {
        "skillname":"Java"
      },
      {
        "skillname":"Reactjs"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"A client"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData